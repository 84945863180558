import { useGetDeliveryLinks } from '@/services/api/delivery';
import { Divider, Group, LoadingOverlay } from '@mantine/core';
import { useGetDraftById } from '@services/api/drafts';
import React from 'react';
import { useParams } from 'react-router-dom';
import { OverviewStats } from './OverviewStats';
import { EmailPreviewWrapper } from './Preview';

const ReportStats = () => {
  const { draftId } = useParams();
  const { data: draft, isLoading } = useGetDraftById(draftId);
  const { data: links, isLoading: isLoadingLinks } = useGetDeliveryLinks(draft?.delivery?.id);

  if (isLoading || isLoadingLinks) return <LoadingOverlay visible />;

  return (
    (draft && draft.delivery)
      ? (
        <>
          <OverviewStats delivery={draft?.delivery} />
          <Divider />
          <EmailPreviewWrapper draft={draft} isLoading={isLoading} />
        </>
      )
      : (
        <Group justify='center' align='center' w={'100%'} h={'100%'}>
          <h1>Report Not Found</h1>
        </Group>
      )
  );
};

export default ReportStats;
