import { useEffect, useState } from 'react';

const useDetectWebPlatform = () => {
  const [platform, setPlatform] = useState('unknown');

  useEffect(() => {
    const detectPlatform = () => {
      const userAgent = navigator.userAgent;

      if (/android/i.test(userAgent)) {
        return 'andoid';
      } else if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios';
      } else if (/Windows|Macintosh|Linux/i.test(userAgent)) {
        return 'desktop';
      } else {
        return 'unknown';
      }
    };

    setPlatform(detectPlatform());
  }, []);

  return platform;
};

export default useDetectWebPlatform;
