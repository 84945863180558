import { Box, CSSProperties, Group, ScrollArea, Stack, Text } from '@mantine/core';
import { ArrowLeftIcon, Cross1Icon } from '@radix-ui/react-icons';

interface Props {
  comments: string[];
  //  onClose: () => void;
  isMobile?: boolean;
  styles?: React.CSSProperties;
  withInput: boolean;
}

const CommentList: React.FC<Props> = ({ comments, isMobile, styles, withInput }) => {
  const _styles: CSSProperties = {
    backgroundColor: 'white',
  };
  if (withInput) {
    _styles.paddingBottom = '10px';
  }
  return (
    <Box
      px={10}
      pt={10}
      style={{
        ..._styles,
        ...styles,
      }}
    >
      <Text
        style={{
          textAlign: 'center',
          lineHeight: '1rem',
        }}
        size={isMobile ? 'lg' : 'xl'}
      >
        Comments
      </Text>

      <Stack
        id='comment-list'
        mt={10}
        gap={10}
        style={{
          overflowY: 'scroll',
          maxHeight: '50vh',
        }}
      >
        {comments.map((c: string, idx: number) => {
          return <CommentBox key={idx} comment={c} />;
        })}
      </Stack>
    </Box>
  );
};

export default CommentList;

const CommentBox = ({ comment }: { comment: string; }) => {
  return (
    <Box
      // mih={50}

      style={{
        border: '1px solid rgb(206, 212, 218)',
        borderRadius: '4px',
      }}
      p={11.5}
    >
      <Text
        style={{
          lineHeight: '25px',
          whiteSpace: 'pre-wrap',
          wordWrap: 'break-word',
        }}
      >
        {comment}
      </Text>
    </Box>
  );
};

{
  /* <Group style={{ marginBottom: '1rem' }} align='center'>
  {mobile && <Box style={{ width: '1.25rem' }} />}
  <Text
    style={{
      textAlign: 'center',
      //margin: 0,
      flex: 1,
    }}
    size={mobile ? 'lg' : 'xl'}
  >
    Comments
  </Text>
  {mobile && <Cross1Icon style={{ height: '1.25em', width: '1.25em' }} onClick={onClose} />}
</Group> */
}
