import { EMAILS_VIEW_TABS } from '@/lib/types';
import { calcAverage, formatToDayDate } from '@/lib/utils';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetAllDrafts, useGetAllDraftsCount } from '@/services/api/drafts';
import { usePagination } from '@/services/hooks/usePagination';
import AppState from '@/services/state/AppState';
import { Web } from '@/sharedTypes';
import { Anchor, Group, Pagination, Stack, Title } from '@mantine/core';
import { Box, Card, Divider, Skeleton, Text } from '@mantine/core';
import { useNavigate } from 'react-router-dom';

export default function DeliveriesList({ withPagination = false }) {
  const appState = AppState.useContainer();

  const { data: draftCount } = useGetAllDraftsCount({ status: EMAILS_VIEW_TABS.SENT }, [appState?.UI?.screen]);

  const { setPage, limit, offset, page, totalPages } = usePagination(5, draftCount?.total);
  const { data: drafts, isLoading: isLoadingDeliveries } = useGetAllDrafts(
    {
      status: EMAILS_VIEW_TABS.SENT,
      limit,
      offset,
    },
    [offset, limit, appState?.UI?.screen],
  );
  const navigate = useNavigate();

  const handleViewDetails = ({ draftId }) => {
    navigate(`/reports/${draftId}`);
  };

  const handleListReports = () => {
    navigate(`/reports`);
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      <Title order={4} fw={600} pb={'md'}>
        Sent Campaigns
      </Title>
      <ReportList
        isLoading={isLoadingDeliveries}
        drafts={drafts}
        onView={handleViewDetails}
      />
      {withPagination
        ? (
          <Group justify='center'>
            <Pagination display={totalPages > 1 ? 'flex' : 'none'} value={page} onChange={setPage} total={totalPages} defaultValue={1} color='mbblue' />
          </Group>
        )
        : (
          <Anchor ta='center' onClick={handleListReports} underline='always'>
            See old campaigns
          </Anchor>
        )}
    </Stack>
  );
}

interface ReportListProps {
  isLoading: boolean;
  drafts: Web.Response.IDraftDelivery[];
  onView: (draftId: any) => void;
}

const ReportList = ({ isLoading, drafts, onView }: ReportListProps) => {
  const calculateRates = (sendTo: number, open: number, click: number) => {
    const clickRate = calcAverage(open, click);
    const openRate = calcAverage(sendTo, open);
    return { clickRate, openRate };
  };

  return (
    <>
      {isLoading
        ? (
          Array.from({ length: 5 }).map((_, index) => (
            <Card key={index} shadow='sm' p='lg' radius='md' withBorder>
              <Skeleton height={30} width='70%' mb='md' />
              <Skeleton height={20} width='90%' mb='md' />
              <Divider />
              <Skeleton height={20} width='50%' mb='md' />
              <Skeleton height={20} radius='md' width='100%' />
              <Skeleton height={20} width='50%' mt='md' />
              <Skeleton height={20} radius='md' width='100%' />
            </Card>
          ))
        )
        : drafts?.length === 0
        ? <MBEmptyGeneric />
        : (
          drafts?.map(draft => {
            const { clickRate, openRate } = calculateRates(draft.delivery.sendTo, draft.delivery.open, draft.delivery.click);

            return (
              <Card key={draft.delivery.id} style={{ cursor: 'pointer' }} onClick={() => onView({ draftId: draft.id })} shadow='sm' p='lg' radius='md' withBorder>
                <Text fw={500} size='lg' lineClamp={2}>{draft.subject}</Text>
                <Text size='sm' c='dimmed'>{draft.preHeader}</Text>
                <Text size='sm' c='dimmed' mt='sm'>
                  {`Sent on ${formatToDayDate(draft.delivery.toBeSentAt)}`}
                </Text>
                <Divider mt='sm' mb='sm' />
                <Group justify='space-evenly'>
                  <Box ta={'center'}>
                    <Text size='sm' c='dimmed'>Open rate</Text>
                    <Text fw={700} size='xl'>{`${parseFloat(openRate.toFixed(2))}%`}</Text>
                  </Box>

                  <Box ta={'center'}>
                    <Text size='sm' c='dimmed'>Click rate</Text>
                    <Text fw={700} size='xl'>{`${parseFloat(clickRate.toFixed(2))}%`}</Text>
                  </Box>
                </Group>
              </Card>
            );
          })
        )}
    </>
  );
};
