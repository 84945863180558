import useDetectWebPlatform from '@/services/hooks/useDetectWebPlatform';
import { Capacitor } from '@capacitor/core';
import { Box, Group, Image, Title } from '@mantine/core';

const DownloadAppBanner = ({ isAuthenticated = false }) => {
  const platform = Capacitor.getPlatform();
  const webPlatform = useDetectWebPlatform();

  const config = {
    android: {
      logo: '/android.png',
      appLink: 'https://play.google.com/store/apps/details?id=ai.mailberry.managed.app',
    },
    ios: {
      logo: '/ios.png',
      appLink: 'https://apps.apple.com/ar/app/mailberry/id6503645864',
    },
  };

  if (isAuthenticated && platform === 'web') {
    return null;
  }

  const handleStoreClick = (appLink: string) => {
    window.open(appLink, '_blank');
  };

  // For non-authenticated users on desktop, show both stores
  if (!isAuthenticated && platform === 'web') {
    return (
      <Group pos={'absolute'} justify='space-evenly' w={365} bottom={-150}>
        <Image
          w={150}
          onClick={() => handleStoreClick(config.ios.appLink)}
          src={window.location.origin + config.ios.logo}
          style={{ cursor: 'pointer' }}
        />
        <Image
          w={150}
          onClick={() => handleStoreClick(config.android.appLink)}
          src={window.location.origin + config.android.logo}
          style={{ cursor: 'pointer' }}
        />
      </Group>
    );
  }

  // For authenticated users on mobile, show current platform store
  if (isAuthenticated && (webPlatform === 'android' || webPlatform === 'ios')) {
    const { logo, appLink } = config[webPlatform];
    return (
      <Box p={20} style={{ borderTop: '1px solid #cccccc' }}>
        <Title ta={'center'} order={5}>Also available in</Title>
        <Image
          onClick={() => handleStoreClick(appLink)}
          src={window.location.origin + logo}
          style={{ cursor: 'pointer' }}
        />
      </Box>
    );
  }

  return null;
};

export default DownloadAppBanner;
