import { useGetDemoDraft } from '@/services/api/demo/draft';
import { useTrackDemoEvent } from '@/services/api/demo/tracking';
import AppState from '@/services/state/AppState';
import { Divider, Group, LoadingOverlay } from '@mantine/core';
import { useTour } from '@reactour/tour';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { OverviewStats } from './OverviewStats';
import { EmailPreviewWrapper } from './Preview';

const DemoReportStats = () => {
  const { draftId } = useParams();
  const { data, isLoading } = useGetDemoDraft(draftId);
  const { setIsOpen, currentStep, setCurrentStep, isOpen } = useTour();
  const trackDemoEvent = useTrackDemoEvent();
  const appState = AppState.useContainer();

  useEffect(() => {
    if (appState.userAndCustomer.isInTour && data?.data?.delivery && currentStep === 11) {
      setCurrentStep(currentStep + 1);
      trackDemoEvent.mutateAsync({ event: 'DemoStep', value: currentStep + 1 }).catch(e => console.error(e));
      setIsOpen(true);
    }
  }, [currentStep, data?.data?.delivery]);

  if (isLoading) return <LoadingOverlay visible />;

  return (
    (data?.data && data.data?.delivery)
      ? (
        <>
          <OverviewStats delivery={data?.data.delivery} />
          {
            /* <Divider />
          <EmailPreviewWrapper draft={data?.data} isLoading={isLoading} /> */
          }
        </>
      )
      : (
        <Group justify='center' align='center' w={'100%'} h={'100%'}>
          <h1>Report Not Found</h1>
        </Group>
      )
  );
};

export default DemoReportStats;
