import { formatToDayDate } from '@/lib/utils';
import MBEmptyGeneric from '@/mailberry/Empty/MBEmptyGeneric';
import { useGetAllLastReview } from '@/services/api/emailReview';
import { REVIEW_STATUS } from '@/sharedTypes';
import { Button, Card, Group, Skeleton, Stack, Text, Title } from '@mantine/core';
import { CiClock1 } from 'react-icons/ci';
import { useNavigate } from 'react-router-dom';
import { STATUS_COLORS } from '../common';

export default function ReviewList() {
  const { data: allLastReview, isLoading: isLoadingReviewList } = useGetAllLastReview();
  const navigate = useNavigate();
  const onClickView = ({ draftId }) => {
    navigate(`/review/${draftId}`);
  };

  return (
    <Stack gap='md' style={{ width: '100%', padding: '20px' }}>
      {isLoadingReviewList
        ? (
          Array.from({ length: 5 }).map((_, index) => <Skeleton key={index} height={100} radius='md' animate />)
        )
        : (
          <Stack gap={0}>
            <Title order={4} fw={600} pb={'md'}>
              Upcoming Campaigns
            </Title>
            <Stack gap='md'>
              {allLastReview?.length === 0
                ? <MBEmptyGeneric />
                : (
                  <>
                    {allLastReview?.map(review => (
                      <Card
                        style={{ cursor: 'pointer' }}
                        key={review.id}
                        shadow='sm'
                        p='lg'
                        radius='md'
                        withBorder
                        onClick={() => onClickView({ draftId: review.draftId })}
                      >
                        <Text fw={500} size='lg'>{review.subject || 'No Subject'}</Text>
                        <Text size='sm' c='dimmed'>{review.preHeader || 'No Preheader'}</Text>
                        {review.status === REVIEW_STATUS.APPROVED
                          && (
                            <Group wrap='nowrap'>
                              <CiClock1 />
                              <Text display={'inline'} size='sm' c='dimmed'>{review.scheduledTo ? `Scheduled for ${formatToDayDate(review.scheduledTo)}` : 'Not yet scheduled'}</Text>
                            </Group>
                          )}
                        <Group justify='space-between'>
                          <Text size='sm' style={{ color: STATUS_COLORS[review.status] }}>
                            {review.status.charAt(0).toUpperCase() + review.status.slice(1).replaceAll('_', ' ')}
                          </Text>
                          <Group justify='right' mt={0}>
                            <Button variant='light' color='blue'>
                              View
                            </Button>
                          </Group>
                        </Group>
                      </Card>
                    ))}
                  </>
                )}
            </Stack>
          </Stack>
        )}
    </Stack>
  );
}
